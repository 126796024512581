import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  Flex,
  Text
} from '@stars-ecom/shared-atoms-ui'
import { ContactCategories, ContactForm, WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import isNil from 'lodash/isNil'
import parseInt from 'lodash/parseInt'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const ContactPage = (props) => {
  const { pageContext } = props
  const { contactPage } = pageContext
  const websiteContext = useContext(WebsiteContext)
  const [subCategory, setSubCategory] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState(0)
  const [selectedSubCategoryLabel, setSelectedSubCategoryLabel] = useState('')
  const [categSereneoId, setCategSereneoId] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('')
  const [answer, setAnswer] = useState()
  const [nature, setNature] = useState()
  const [alert, setAlert] = useState()
  const AnswerFAQ = ({ answer }) => {
    return (
      <Box mt={{ md: '20px' }} mb={{ base: '50px', md: '20px' }}>
        <Box
          fontFamily={websiteContext?.fontFamily}
          dangerouslySetInnerHTML={{ __html: answer }}
          sx={{
            table: {
              margin: 'auto'
            },
            '& a': {
              color: websiteContext?.mainColor,
              '&:hover': {
                textDecoration: 'underline',
                color: websiteContext?.darkColor
              }
            },
            '& ul': {
              paddingLeft: '40px',
              margin: '12px 0'
            },
            '& p, li': {
              margin: '12px 0',
              fontSize: '14px'
            },
            '& div.tel': {
              width: '132px',
              border: '1px solid #c5c5c5',
              '& div > p': {
                margin: '0'
              },
              '& div:nth-child(1)': {
                backgroundColor: '#fff',
                '& > p': {
                  fontSize: '16px',
                  fontFamily: 'Arial,sans-serif',
                  fontWeight: '700',
                  textAlign: 'center',
                  color: '#A50F78'
                }
              },
              '& div:nth-child(2)': {
                position: 'relative',
                backgroundColor: '#A50F78',
                padding: '6px 12px 6px 19px',
                lineHeight: '10px',
                '& > p': {
                  color: '#fff',
                  fontSize: '10px',
                  textAlign: 'left'
                },
                '&::before': {
                  content: '" "',
                  position: 'absolute',
                  display: 'block',
                  border: '6px',
                  borderStyle: 'solid',
                  borderColor: 'transparent',
                  borderLeftColor: '#fff',
                  left: '8px',
                  top: '-1px',
                  transform: 'rotate(90deg)'
                }
              }
            }
          }}
        />
      </Box>
    )
  }
  const handleCategoryChange = (e) => {
    if (e.target.value === '') {
      setSubCategory([])
      setSelectedSubCategory(0)
      setAnswer()
    } else {
      const items = contactPage?.contactCategories?.find(
        (categ) => categ?.category?.id === e.target.value
      )
      setSelectedCategory(e.target.value)
      setSubCategory(items?.category?.contactItems)
    }
  }
  const handleSubCategoryChange = (e) => {
    const item = subCategory?.find((categ) => categ?.externalCode === e.target.value)
    setAnswer(item?.faq_question?.answer)
    setNature(item?.Nature)
    setSelectedSubCategory(e.target.value ? parseInt(e.target.value) : 0)
  }

  useEffect(() => {
    const categoryItem = contactPage?.contactCategories?.find(
      (c) => c?.category?.id == selectedCategory
    )
    setSelectedCategoryLabel(categoryItem?.category?.title || '')
    setCategSereneoId(categoryItem?.category?.idSereneo || 0)

    const subCategoryItem = categoryItem?.category?.contactItems?.find(
      (c) => c?.externalCode == selectedSubCategory
    )
    setSelectedSubCategoryLabel(subCategoryItem?.title || '')
  }, [selectedCategory, selectedSubCategory])
  return (
    <Box backgroundColor="#f6f3f1" m="auto" width="100%" padding={{ base: '0 15px', md: 'auto' }}>
      <Box maxW="1000px" ml="auto" mr="auto">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Contactez l'équipe ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
          <meta
            name="description"
            content={`Contactez l'équipe ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
          />
        </Helmet>
        <Text
          as="h1"
          color="#333"
          lineHeight="60px"
          fontSize={{ base: '28px', md: '60px' }}
          fontFamily={{
            base: 'PT Sans, Arial, sans-serif',
            md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
          }}
          pt={{ md: '50px' }}
          pb={{ md: '40px' }}>
          {contactPage?.title}
        </Text>
        {alert && (
          <Alert mb="12px" status={alert.status}>
            <AlertIcon />
            <Flex align={{ md: 'center' }} w="100%" justifyContent="space-between">
              <AlertDescription>{alert.message}</AlertDescription>
              <CloseButton
                alignItems="flex-start"
                justifyContent="flex-end"
                paddingTop="8px"
                onClick={() => {
                  setAlert(null)
                }}
              />
            </Flex>
          </Alert>
        )}
        <Flex
          direction="column"
          mb={{ md: '50px' }}
          style={{
            fontSize: '14px',
            color: '#333',
            lineHeight: '1.5',
            fontFamily: websiteContext?.fontFamily
          }}
          dangerouslySetInnerHTML={{ __html: contactPage?.hook }}
        />

        <ContactCategories
          category={contactPage?.contactCategories}
          subCategory={subCategory}
          handleSubCategoryChange={handleSubCategoryChange}
          handleCategoryChange={handleCategoryChange}
        />
        {!isNil(answer) && <AnswerFAQ answer={answer} />}
        {isNil(answer) && (
          <ContactForm
            subCateg={selectedSubCategory}
            categSereneoId={categSereneoId}
            categ={selectedCategory}
            categoryLabel={selectedCategoryLabel}
            subCategoryLabel={selectedSubCategoryLabel}
            nature={nature}
            endPoint={contactPage?.externalServiceEndpoint}
            contactEmail={contactPage?.contactEmail}
            setAlert={setAlert}
          />
        )}

        <Box
          mt="20px"
          mb="10px"
          fontSize="11px"
          fontFamily={websiteContext?.fontFamily}
          color="#5e5e5e"
          align="justify"
          sx={{
            '& a': {
              color: websiteContext?.mainColor,
              '&:hover': {
                textDecoration: 'underline',
                color: websiteContext?.darkColor
              }
            }
          }}
          dangerouslySetInnerHTML={{ __html: websiteContext?.legalMention }}
        />
      </Box>
    </Box>
  )
}

ContactPage.pageType = 'contact'

export default ContactPage
